import React, {Component} from 'react';
import {Route, Router, Switch, Redirect} from 'react-router-dom';
import history from "./services/history";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PasswordEmail from "./views/Password/PasswordEmail";
import PasswordReset from "./views/Password/PasswordReset";
import AuthContextProvider, {AuthContext} from "./Contexts/AuthContext";
import './App.scss';
import Loadspinner from "./containers/DefaultLayout/Loadspinner";

toast.configure()

const loading = () => <Loadspinner/>

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path="/login" name="Login Page" render={props =>
              <AuthContextProvider>
                <Login {...props}/>
              </AuthContextProvider>
            }/>
            <Route exact path="/password/email" name="Forgot Password" render={props =>
              <PasswordEmail {...props}/>
            }/>
            <Route exact path="/password/reset" name="Reset Password" render={props =>
              <PasswordReset {...props}/>
            }/>
            <Route exact path="/404" name="Page 404" render={props =>
              <Page404 {...props}/>
            }/>
            <Route exact path="/500" name="Page 500" render={props =>
              <Page500 {...props}/>
            }/>
            <Route path="/" name="Home" render={props =>
              <AuthContextProvider>
                <AuthContext.Consumer>
                  {(authContext) => {
                    if (authContext.isAuthenticated)
                      return (
                        <DefaultLayout {...props}/>
                      )
                    else
                      return (
                        <Redirect from="/" to="/login"/>
                      )
                  }}
                </AuthContext.Consumer>
              </AuthContextProvider>
            }/>

          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
