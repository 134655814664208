import  React, { createContext, useEffect, useState} from 'react'

export const AuthContext = createContext({});

const AuthContextProvider = (props) => {
    const localData = localStorage.getItem('user_auth');
    const defaultState = {
        isAuthenticated: false,
    };
    const [userAuth, setUserAuth] = useState(localData? JSON.parse(localData):defaultState);
    useEffect(()=>{
        localStorage.setItem('user_auth', JSON.stringify(userAuth));
    });

    const changeState=(loginInfo)=>{
        if(loginInfo.isAuthenticated){
            setUserAuth({
                isAuthenticated: true,
            });
        }else{
            setUserAuth(defaultState);
        }
        localStorage.setItem('user_auth', JSON.stringify(userAuth));
    };

    return (
        <AuthContext.Provider value={{...userAuth, changeState:(loginInfo) => changeState(loginInfo)}}>
            {props.children}
        </AuthContext.Provider>
        );
}

export default AuthContextProvider;
