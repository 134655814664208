import React from "react";
import {BeatLoader} from "react-spinners";

// Can be a string as well. Need to ensure each key-value pair ends with ;

class Loadspinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  render() {
    return (
      <div className="sweet-loading d-flex justify-content-center"  >
        <BeatLoader
          size={15}
          color={"#000000"}
          loading={this.state.loading}
      />
  </div>
  )
    ;
  }
}

export default Loadspinner;
