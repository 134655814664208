import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form, FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import Axios from "axios";
import {Link} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

class PasswordEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {email: '', errors: {}};

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleFormSubmit(event) {
    event.preventDefault();
    Axios.defaults.withCredentials = true;
    Axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    Axios.get('/sanctum/csrf-cookie').then(response => {
      Axios.post("api/password/email", this.state).then(response => {
        this.props.history.push({
          pathname: "/login",
          state: {message: response.data.message, type: 'success'}
        });
      }).catch(error => {
        this.setState({
          errors: error.response.data.errors
        });
      });
    });
  };

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="p-4">
                <CardBody>
                  <Form method="POST" onSubmit={this.handleFormSubmit}>
                    <h1>Reset Password</h1>
                    <p className="text-muted">Send email to reset your password</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text"
                             name="email"
                             value={this.state.email}
                             onChange={this.handleFieldChange}
                             placeholder="Email"
                             invalid={(this.state.errors.hasOwnProperty('email'))}
                             autoFocus
                      />
                      <FormFeedback> {(this.state.errors.hasOwnProperty('email')) ? this.state.errors.email : ''} </FormFeedback>
                    </InputGroup>
                    <Row>
                      <Col xs="8">
                        <Button color="primary" className="px-4">Send Password Reset Link</Button>
                      </Col>
                      <Col xs="4" className="text-right">
                        <Link to="/login">
                          <Button color="link" className="px-0">Back to Login</Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PasswordEmail;
